<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		animationState: boolean;
		isDesktop?: boolean;
	}>(),
	{ animationState: false }
);

const emit = defineEmits<{ (event: "handleClickWithDelay"): void }>();

const elementStyles = reactive<{ top: string; left: string; position: "static" | "fixed"; transition: string }>({
	top: "0",
	left: "0",
	position: "static",
	transition: "0"
});

const isAnimated = ref(false);
const animationContainer = ref<HTMLDivElement>();

const startQuickCashAnimation = async () => {
	if (!props.isDesktop) {
		await animationContainer?.value?.scrollIntoView({ block: "center" });
	}

	const containerRectTop = animationContainer.value?.getBoundingClientRect().top;
	const containerRectLeft = animationContainer.value?.getBoundingClientRect().left;

	elementStyles.top = `${containerRectTop}px`;
	elementStyles.left = `${containerRectLeft}px`;
	elementStyles.position = "fixed";

	setTimeout(() => {
		elementStyles.transition = "0.5s";
		isAnimated.value = true;
		isAnimated.value = true;
		document.body.style.overflow = "hidden";
	}, 50);

	setTimeout(() => {
		emit("handleClickWithDelay");
	}, 500);
};

const stopQuickCashAnimation = () => {
	if (!isAnimated.value) {
		return;
	}
	isAnimated.value = false;
	elementStyles.transition = "0.15s";

	setTimeout(() => {
		elementStyles.top = "0px";
		elementStyles.left = "0px";
		elementStyles.position = "static";
		elementStyles.transition = "0s";
		document.body.style.overflow = "";
	}, 200);
};

watch(
	() => props.animationState,
	(newVal) => {
		if (newVal) {
			startQuickCashAnimation();
		} else {
			stopQuickCashAnimation();
		}
	}
);
</script>

<template>
	<div class="wrapper" :class="{ 'wrapper-animated': isAnimated }">
		<div ref="animationContainer" class="block" :class="{ animated: isAnimated }" :style="elementStyles">
			<slot />
		</div>
	</div>
</template>

<style scoped lang="scss">
.wrapper {
	width: 330px;
	height: 310px;
	max-width: 100%;
	border-radius: 10px;

	&-animated {
		box-shadow: inset 0 0 30px 2px var(--сelaya);
		position: relative;
		z-index: 3000;

		@include media-breakpoint-down(lg) {
			box-shadow: none;
		}
	}
}

.animated {
	top: calc(50% - 155px) !important;
	left: calc(50% - 165px) !important;
	animation: fadeInUpText 0.5s ease-out forwards;
}

@keyframes fadeInUpText {
	30% {
		transform: rotateY(0);
		opacity: 1;
	}
	75% {
		opacity: 0.4;
	}
	100% {
		transform: rotateY(180deg);
		opacity: 0.5;
	}
}
</style>
